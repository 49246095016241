var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("TheSnapshotMoveAndCopyFiles", {
        attrs: {
          selectedFiles: _vm.filesToMoveOrCopy,
          operationType: _vm.copyOrMoveOperation,
          folderTypeSelected: _vm.folderTypeSelected,
        },
        on: {
          clearCopyAndMoveFiles: function ($event) {
            return _vm.clearCopyAndMoveFiles()
          },
          removeFileFromMoveCopyList: function ($event) {
            return _vm.removeFileFromMoveCopyList($event.fid)
          },
        },
      }),
      _vm.snapshotFilesystemPrefix
        ? _c(
            "div",
            {
              staticClass:
                "d-flex justify-space-between align-space-between w-100",
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      !_vm.filesFetching &&
      (_vm.currentFiles.length || _vm.pathArray.length > 1) &&
      _vm.snapshotFilesystemPrefix
        ? _c(
            "div",
            { staticClass: "pt-0", attrs: { width: "50%", fluid: "" } },
            [
              _c(
                "v-snackbar",
                {
                  attrs: { timeout: _vm.snackbarTimeout, color: "info" },
                  model: {
                    value: _vm.stageSuggestionSnackbar,
                    callback: function ($$v) {
                      _vm.stageSuggestionSnackbar = $$v
                    },
                    expression: "stageSuggestionSnackbar",
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { dark: "" } }, [
                    _vm._v("info"),
                  ]),
                  _vm._v(" Add uploaded files to stage to share with others? "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.addToStaging(
                            _vm.lastUploadedFiles,
                            "uploaded"
                          )
                        },
                      },
                    },
                    [_vm._v("Stage")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", text: "" },
                      on: { click: _vm.discardUploaded },
                    },
                    [_vm._v("Discard")]
                  ),
                ],
                1
              ),
              _c("v-breadcrumbs", {
                staticClass: "pt-3 pb-5",
                attrs: { large: "", items: _vm.pathArray },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (props) {
                        return [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$store.dispatch(
                                    "snapshotStore/setTreeLevel",
                                    {
                                      snid: _vm.$route.params.snid,
                                      level: props.item.index + 1,
                                    }
                                  )
                                },
                              },
                            },
                            [
                              props.item.index > 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold secondary--text",
                                    },
                                    [_vm._v(_vm._s(props.item.path))]
                                  )
                                : _vm.pathArray.length === 1
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("folder")]
                                  )
                                : _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("mdi-folder-open")]
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "divider",
                      fn: function () {
                        return [_c("v-icon", [_vm._v("double_arrow")])]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3949772418
                ),
              }),
              _c("v-text-field", {
                attrs: {
                  autofocus: "",
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  label: "Filter files...",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.filesFetching
        ? _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { type: "table" },
          })
        : !_vm.snapshotFilesystemPrefix
        ? _c(
            "v-banner",
            { attrs: { "single-line": "" } },
            [
              _c(
                "v-avatar",
                {
                  attrs: { slot: "icon", color: "white", size: "60" },
                  slot: "icon",
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { "x-large": "", icon: "info", color: "info" } },
                    [_vm._v("info")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _vm.isInstanceEditor && _vm.isDevelopment
                    ? [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold secondary--text" },
                          [_vm._v("Archived state")]
                        ),
                        _c("p", [
                          _vm._v(
                            " Files are not available in the Current State of this instance. To access files, you may either "
                          ),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToSnapshots()
                                },
                              },
                            },
                            [_vm._v("restore")]
                          ),
                          _vm._v(
                            " a snapshot to Current State or browse files of snapshots. "
                          ),
                        ]),
                      ]
                    : _vm.isInstanceEditor && !_vm.isDevelopment
                    ? [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold secondary--text" },
                          [_vm._v("Snapshot in cold storage")]
                        ),
                        _c("p", [
                          _vm._v(
                            " This snapshot is in cold storage, its files cannot be accessed directly. To access its files, you must first "
                          ),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToSnapshots()
                                },
                              },
                            },
                            [_vm._v("restore")]
                          ),
                          _vm._v(" it to the Current State. "),
                        ]),
                      ]
                    : [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold secondary--text" },
                          [_vm._v("Snapshot in cold storage")]
                        ),
                        _c("p", [
                          _vm._v(
                            " This snapshot is in cold storage and you don't have the privileges to restore it. You may try browsing other snapshots that are not in cold storage, or reach out to support for retrieval. "
                          ),
                        ]),
                      ],
                ],
                2
              ),
            ],
            1
          )
        : _vm.pathArray.length === 1 && !_vm.currentFiles.length
        ? _c(
            "v-banner",
            { attrs: { "single-line": "" } },
            [
              _c(
                "v-avatar",
                {
                  attrs: { slot: "icon", color: "white", size: "60" },
                  slot: "icon",
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { "x-large": "", icon: "info", color: "info" } },
                    [_vm._v("info")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "span",
                  { staticClass: "font-weight-bold secondary--text" },
                  [_vm._v("No files found")]
                ),
                _c("span", [
                  _vm._v(" Currently you don't have "),
                  _vm.folderTypeSelected ===
                  _vm.folderTypesLabels.WORKSPACE_FILES
                    ? _c("span", [_vm._v("workspace")])
                    : _vm.folderTypeSelected ===
                      _vm.folderTypesLabels.PERSONAL_FILES
                    ? _c("span", [_vm._v("personal")])
                    : _vm._e(),
                  _vm._v(" files. "),
                  _vm.isDevelopment
                    ? _c(
                        "span",
                        [
                          _vm._v(" Consider adding new files via the "),
                          _c("v-icon", [_vm._v("upload")]),
                          _vm._v(" icon above "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" or you can also "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://docs.nuvolos.cloud/features/file-system-and-storage/synchronize-with-dropbox",
                        target: "_blank",
                      },
                    },
                    [_vm._v(" set up Dropbox integration ")]
                  ),
                  _vm._v(" . "),
                ]),
              ]),
            ],
            1
          )
        : _c("v-data-table", {
            staticClass: "shepherd-staging-step-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.getTableData(_vm.filesFetching, _vm.currentFiles),
              "show-select": _vm.currentFiles.length > 0,
              "item-key": "fid",
              options: _vm.pagination,
              search: _vm.search,
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function ({ options, pagination, updateOptions }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("v-switch", {
                          staticClass: "pr-3 pl-3 mb-0",
                          attrs: { label: "Hidden Files" },
                          model: {
                            value: _vm.showHidden,
                            callback: function ($$v) {
                              _vm.showHidden = $$v
                            },
                            expression: "showHidden",
                          },
                        }),
                        _c("v-spacer"),
                        _c("v-data-footer", {
                          staticStyle: { "border-top": "none" },
                          attrs: {
                            "prev-icon": "mdi-arrow-left-circle",
                            "next-icon": "mdi-arrow-right-circle",
                            pagination: pagination,
                            "items-per-page-options": _vm.itemsPerPageOptions,
                            options: options,
                          },
                          on: { "update:options": updateOptions },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "page-text",
                                fn: function (pagetext) {
                                  return [
                                    _c(
                                      "v-alert",
                                      {
                                        staticClass: "py-1 px-2 my-0",
                                        attrs: {
                                          color: "info",
                                          text: "",
                                          dense: "",
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(
                                            " Listing files " +
                                              _vm._s(pagetext.pageStart) +
                                              " - " +
                                              _vm._s(pagetext.pageStop) +
                                              " (out of " +
                                              _vm._s(pagetext.itemsLength) +
                                              ") "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm.currentFiles.length
                          ? _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-content-click": false,
                                  "offset-y": "",
                                  bottom: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-badge",
                                            {
                                              attrs: {
                                                color: _vm.selected.length
                                                  ? "primary"
                                                  : "transparent",
                                                content: _vm.selected.length,
                                                overlap: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass: "caption",
                                                    attrs: {
                                                      color: "secondary",
                                                      icon: "",
                                                      dark: "",
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("more_vert"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.multipleFilesActionsMenu,
                                  callback: function ($$v) {
                                    _vm.multipleFilesActionsMenu = $$v
                                  },
                                  expression: "multipleFilesActionsMenu",
                                },
                              },
                              [
                                _c(
                                  "v-list",
                                  { attrs: { dense: "", nav: "" } },
                                  [
                                    _vm.mergedOptions.stage
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.currentFiles
                                                          .length,
                                                      color: "secondary",
                                                      block: "",
                                                      text: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addToStaging(
                                                          _vm.currentFiles,
                                                          "selected"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex aling-center justify-start w-100",
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v("share")]
                                                        ),
                                                        _vm._v(
                                                          " stage all (" +
                                                            _vm._s(
                                                              _vm.currentFiles
                                                                .length
                                                            ) +
                                                            ") "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.mergedOptions.stage
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "justify-start",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.selected.length,
                                                      color: "secondary",
                                                      block: "",
                                                      text: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addToStaging(
                                                          _vm.selected,
                                                          "selected"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: { small: "" },
                                                      },
                                                      [_vm._v("share")]
                                                    ),
                                                    _vm._v(" stage selected "),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.groupMenuFirstDivider()
                                      ? _c("v-divider", { staticClass: "my-3" })
                                      : _vm._e(),
                                    _vm.mergedOptions.download
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.multipleFilesDownloadLink ===
                                                          null ||
                                                        _vm.fetchingMultipleDownloadToken,
                                                      href: _vm.multipleFilesDownloadLink,
                                                      color: "secondary",
                                                      text: "",
                                                      block: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center justify-start w-100",
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-download"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" download "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.selected.length > 10
                                              ? _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { right: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "grey lighten-1",
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-information"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Downloading multiple files is possible only for a maximum of 10 files."
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isDevelopment
                                      ? [
                                          _vm.mergedOptions.move
                                            ? _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.selected
                                                                .length,
                                                            color: "secondary",
                                                            text: "",
                                                            block: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addToMoveCopyList(
                                                                _vm.selected,
                                                                "move",
                                                                true
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center justify-start w-100",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-folder-move"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" move "),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.mergedOptions.copy
                                            ? _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.selected
                                                                .length,
                                                            color: "secondary",
                                                            text: "",
                                                            block: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addToMoveCopyList(
                                                                _vm.selected,
                                                                "copy",
                                                                true
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center justify-start w-100",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "content_copy"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" copy "),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.groupMenuSecondDivider()
                                            ? _c("v-divider", {
                                                staticClass: "my-3",
                                              })
                                            : _vm._e(),
                                          _vm.mergedOptions.delete
                                            ? _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "subtitle-2 secondary--text",
                                                    },
                                                    [
                                                      _c("DeleteButton", {
                                                        attrs: {
                                                          objectType: "file",
                                                          buttonName:
                                                            _vm.getDeleteLabel,
                                                          protocol: "POST",
                                                          "request-body": {
                                                            fids: _vm.selectedFileIds,
                                                            mode: 0,
                                                          },
                                                          apiURL:
                                                            "/files/delete_async",
                                                          disabled:
                                                            _vm.selected
                                                              .length === 0,
                                                          isAsyncDeletion: true,
                                                          showDialog: true,
                                                          warningText:
                                                            _vm.confirmQuestion(
                                                              0
                                                            ),
                                                          fetchString:
                                                            "snapshotStore/fetchCurrentFiles",
                                                          objectName:
                                                            _vm.selectedFileNames.toString(),
                                                        },
                                                        on: {
                                                          error: function (
                                                            $event
                                                          ) {
                                                            return _vm.errorMessage(
                                                              $event.error
                                                            )
                                                          },
                                                          deleting: function (
                                                            $event
                                                          ) {
                                                            return _vm.emptySelected(
                                                              !$event.value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _vm.isSpaceAdmin &&
                                    _vm.mergedOptions.delete &&
                                    _vm.currentSpaceType !==
                                      _vm.spaceTypes.RESEARCH_SPACE
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "subtitle-2 secondary--text",
                                              },
                                              [
                                                _c("DeleteButton", {
                                                  attrs: {
                                                    objectType: "file",
                                                    buttonName:
                                                      _vm.getDeleteForOthersLabel,
                                                    protocol: "POST",
                                                    "request-body": {
                                                      fids: _vm.selectedFileIds,
                                                      mode: 2,
                                                    },
                                                    apiURL:
                                                      "/files/delete_async",
                                                    disabled:
                                                      _vm.selected.length === 0,
                                                    isAsyncDeletion: true,
                                                    showDialog: true,
                                                    warningText:
                                                      _vm.confirmQuestion(2),
                                                    fetchString:
                                                      "snapshotStore/fetchCurrentFiles",
                                                    objectName:
                                                      _vm.selectedFileNames.toString(),
                                                  },
                                                  on: {
                                                    error: function ($event) {
                                                      return _vm.errorMessage(
                                                        $event.error
                                                      )
                                                    },
                                                    deleting: function (
                                                      $event
                                                    ) {
                                                      return _vm.emptySelected(
                                                        !$event.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isDevelopment &&
                                    _vm.isSpaceAdmin &&
                                    _vm.isMasterInstance &&
                                    _vm.mergedOptions.delete &&
                                    _vm.currentSpaceType !==
                                      _vm.spaceTypes.RESEARCH_SPACE
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "subtitle-2 secondary--text",
                                              },
                                              [
                                                _c("DeleteButton", {
                                                  attrs: {
                                                    objectType: "file",
                                                    buttonName:
                                                      "Delete for all",
                                                    protocol: "POST",
                                                    "request-body": {
                                                      fids: _vm.selectedFileIds,
                                                      mode: 1,
                                                    },
                                                    apiURL:
                                                      "/files/delete_async",
                                                    disabled:
                                                      _vm.selected.length === 0,
                                                    isAsyncDeletion: true,
                                                    showDialog: true,
                                                    warningText:
                                                      _vm.confirmQuestion(1),
                                                    fetchString:
                                                      "snapshotStore/fetchCurrentFiles",
                                                    objectName:
                                                      _vm.selectedFileNames.toString(),
                                                  },
                                                  on: {
                                                    error: function ($event) {
                                                      return _vm.errorMessage(
                                                        $event.error
                                                      )
                                                    },
                                                    deleting: function (
                                                      $event
                                                    ) {
                                                      return _vm.emptySelected(
                                                        !$event.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "body",
                fn: function ({ items }) {
                  return [
                    _c(
                      "tbody",
                      [
                        _vm._l(items, function (item) {
                          return _c(
                            "tr",
                            {
                              key: item.fid,
                              attrs: { search: _vm.search },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.selectItem(item)
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "pa-0 ma-0",
                                    attrs: {
                                      multiple: "",
                                      value: item,
                                      "hide-details": "",
                                      flat: "",
                                    },
                                    model: {
                                      value: _vm.selected,
                                      callback: function ($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", [
                                item.type === _vm.nuvolosObjectTypes.FOLDER
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c("v-icon", { staticClass: "mr-3" }, [
                                          _vm._v("folder"),
                                        ]),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "secondary--text subtitle-2",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openDirectory(
                                                  item.short_id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.short_id))]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "offset-y": "",
                                              right: "",
                                              "min-width": "0",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "text-none",
                                                            attrs: { text: "" },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.fetchFileDownloadToken(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.fileIcon(
                                                                    item.type,
                                                                    item.short_id
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.short_id
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "showOnHover",
                                                              attrs: {
                                                                right: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-chevron-down"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              { attrs: { dense: "", nav: "" } },
                                              [
                                                item.short_id &&
                                                item.short_id
                                                  .toLowerCase()
                                                  .endsWith(".pdf") &&
                                                _vm.mergedOptions.view
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "pdf-viewer",
                                                              {
                                                                attrs: {
                                                                  pdfUrl:
                                                                    _vm.previewBlobUrl,
                                                                  longId:
                                                                    _vm.previewLongId,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      text: "",
                                                                      loading:
                                                                        _vm.downloadingFileForPreview,
                                                                      block: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.previewFile(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-center justify-start w-100",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-eye"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " view "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.short_id &&
                                                item.short_id
                                                  .toLowerCase()
                                                  .endsWith(".ipynb")
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "notebook-viewer",
                                                              {
                                                                attrs: {
                                                                  notebookContent:
                                                                    _vm.notebookContent,
                                                                  longId:
                                                                    _vm.nbFile,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "justify-start",
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      text: "",
                                                                      loading:
                                                                        _vm.downloadingFileForPreview,
                                                                      block: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.previewNotebook(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          left: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-eye"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " view "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.mergedOptions.download
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "justify-start",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentFileDownloadLink ===
                                                                      null ||
                                                                    _vm.fetchingFileToken,
                                                                  href: _vm.currentFileDownloadLink,
                                                                  color:
                                                                    "secondary",
                                                                  text: "",
                                                                  block: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      left: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-download"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " download "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.isDevelopment &&
                                                _vm.isEditableFile(item) &&
                                                _vm.mergedOptions.view &&
                                                !item.short_id
                                                  .toLowerCase()
                                                  .endsWith(".ipynb")
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "the-snapshot-file-editor",
                                                              {
                                                                attrs: {
                                                                  endpoint:
                                                                    _vm.endpoint,
                                                                  fileData:
                                                                    item,
                                                                  language:
                                                                    _vm.fileLanguage(
                                                                      item
                                                                    ),
                                                                  isMarkdownFile:
                                                                    _vm.fileLanguage(
                                                                      item
                                                                    ) ===
                                                                    _vm
                                                                      .editorLanguages
                                                                      .md,
                                                                  isBlock: true,
                                                                  loading:
                                                                    _vm.isEditableFileFetching,
                                                                  editable:
                                                                    _vm
                                                                      .mergedOptions
                                                                      .edit,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ]),
                              _c(
                                "td",
                                [
                                  _c("FileStatus", {
                                    attrs: {
                                      fileData: item,
                                      options: _vm.fileStatusOptions,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", { staticClass: "text-right" }, [
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v(_vm._s(_vm.humanFileSize(item.size))),
                                ]),
                              ]),
                              _c(
                                "td",
                                { staticClass: "text-right" },
                                [
                                  item === _vm.selectedItem
                                    ? [
                                        _vm.$vuetify.breakpoint.mdAndUp &&
                                        _vm.mergedOptions.stage
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  color: "secondary",
                                                  small: "",
                                                  outlined: "",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addToStaging(
                                                      [item],
                                                      "selected"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("share")]
                                                ),
                                                _vm._v(" stage "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "close-on-content-click": false,
                                              left: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-3",
                                                            attrs: {
                                                              text: "",
                                                              color:
                                                                "secondary",
                                                              small: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.fetchFileDownloadToken(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "more_horiz"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "ma-0 pt-1 pb-1 pl-0 pr-0",
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      {
                                                        attrs: {
                                                          nav: "",
                                                          dense: "",
                                                        },
                                                      },
                                                      [
                                                        _vm.mergedOptions
                                                          .download
                                                          ? _c(
                                                              "v-list-item",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "justify-start",
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.currentFileDownloadLink ===
                                                                              null ||
                                                                            _vm.fetchingFileToken,
                                                                          href: _vm.currentFileDownloadLink,
                                                                          download:
                                                                            item.type ===
                                                                            _vm
                                                                              .nuvolosObjectTypes
                                                                              .FOLDER
                                                                              ? `${item.short_id}.zip`
                                                                              : item.short_id,
                                                                          color:
                                                                            "secondary",
                                                                          text: "",
                                                                          block:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-download"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " download "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.isDevelopment
                                                          ? [
                                                              item.short_id &&
                                                              item.short_id
                                                                .toLowerCase()
                                                                .endsWith(
                                                                  ".pdf"
                                                                ) &&
                                                              _vm.mergedOptions
                                                                .view
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _c(
                                                                            "pdf-viewer",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  pdfUrl:
                                                                                    _vm.previewBlobUrl,
                                                                                  longId:
                                                                                    _vm.previewLongId,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticClass:
                                                                                    "justify-start",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "secondary",
                                                                                      text: "",
                                                                                      loading:
                                                                                        _vm.downloadingFileForPreview,
                                                                                      block:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.previewFile(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-eye"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " view "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              item.short_id &&
                                                              item.short_id
                                                                .toLowerCase()
                                                                .endsWith(
                                                                  ".ipynb"
                                                                )
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              staticClass:
                                                                                "justify-start",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "secondary",
                                                                                  text: "",
                                                                                  loading:
                                                                                    _vm.downloadingFileForPreview,
                                                                                  block:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.previewNotebook(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      left: "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-eye"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " view "
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.itemMenuFirstDivider()
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      staticClass:
                                                                        "my-3",
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm.mergedOptions
                                                                .rename
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 secondary--text",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "RenameButton",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  selected:
                                                                                    [
                                                                                      item,
                                                                                    ],
                                                                                  objectType:
                                                                                    "file",
                                                                                  dispatchFunction:
                                                                                    () => {
                                                                                      _vm.$store.dispatch(
                                                                                        "snapshotStore/fetchCurrentFiles",
                                                                                        {
                                                                                          id: _vm
                                                                                            .$route
                                                                                            .params
                                                                                            .snid,
                                                                                          route:
                                                                                            _vm.$route,
                                                                                          setFetchingStatus: true,
                                                                                        }
                                                                                      )
                                                                                    },
                                                                                },
                                                                              on: {
                                                                                finished:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.emptySelected(
                                                                                      $event.value
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .smAndDown &&
                                                              _vm.mergedOptions
                                                                .stage
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 secondary--text",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "secondary",
                                                                                  text: "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addToStaging(
                                                                                      [
                                                                                        item,
                                                                                      ],
                                                                                      "selected"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1",
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-playlist-plus"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " stage "
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.mergedOptions
                                                                .move
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 secondary--text",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "secondary",
                                                                                  text: "",
                                                                                  block:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addToMoveCopyList(
                                                                                      [
                                                                                        item,
                                                                                      ],
                                                                                      "move"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex align-center justify-start w-100",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-folder-move"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " move "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isDevelopment &&
                                                              _vm.isEditableFile(
                                                                item
                                                              ) &&
                                                              _vm.mergedOptions
                                                                .view
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _c(
                                                                            "the-snapshot-file-editor",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  endpoint:
                                                                                    _vm.endpoint,
                                                                                  fileData:
                                                                                    item,
                                                                                  language:
                                                                                    _vm.fileLanguage(
                                                                                      item
                                                                                    ),
                                                                                  isMarkdownFile:
                                                                                    _vm.fileLanguage(
                                                                                      item
                                                                                    ) ===
                                                                                    _vm
                                                                                      .editorLanguages
                                                                                      .md,
                                                                                  isBlock: true,
                                                                                  loading:
                                                                                    _vm.isEditableFileFetching,
                                                                                  editable:
                                                                                    _vm
                                                                                      .mergedOptions
                                                                                      .edit,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.mergedOptions
                                                                .copy
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 secondary--text",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "secondary",
                                                                                  text: "",
                                                                                  block:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addToMoveCopyList(
                                                                                      [
                                                                                        item,
                                                                                      ],
                                                                                      "copy"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex align-center justify-start w-100",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "content_copy"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " copy "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              item.type !==
                                                                _vm
                                                                  .nuvolosObjectTypes
                                                                  .FOLDER &&
                                                              _vm.mergedOptions
                                                                .duplicate
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 secondary--text",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  block:
                                                                                    "",
                                                                                  color:
                                                                                    "secondary",
                                                                                  text: "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.duplicateFile(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex align-center justify-start w-100",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-content-duplicate"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " duplicate "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.groupMenuSecondDivider()
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      staticClass:
                                                                        "my-3",
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm.mergedOptions
                                                                .delete
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 secondary--text",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "DeleteButton",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  objectType:
                                                                                    "file",
                                                                                  buttonName:
                                                                                    _vm.getDeleteLabel,
                                                                                  protocol:
                                                                                    "POST",
                                                                                  "request-body":
                                                                                    {
                                                                                      fids: [
                                                                                        item.fid,
                                                                                      ],
                                                                                      mode: 0,
                                                                                    },
                                                                                  apiURL:
                                                                                    "/files/delete_async",
                                                                                  isAsyncDeletion: true,
                                                                                  showDialog: true,
                                                                                  warningText: `Are you sure you want to delete - ${item.short_id.toUpperCase()} - permanently?`,
                                                                                  fetchString:
                                                                                    "snapshotStore/fetchCurrentFiles",
                                                                                  objectName:
                                                                                    item.short_id,
                                                                                },
                                                                              on: {
                                                                                error:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.errorMessage(
                                                                                      $event.error
                                                                                    )
                                                                                  },
                                                                                deleting:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.emptySelected(
                                                                                      !$event.value
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                        _vm.isSpaceAdmin &&
                                                        _vm.mergedOptions.delete
                                                          ? _c(
                                                              "v-list-item",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "subtitle-2 secondary--text",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "DeleteButton",
                                                                      {
                                                                        attrs: {
                                                                          objectType:
                                                                            "file",
                                                                          buttonName:
                                                                            _vm.getDeleteForOthersLabel,
                                                                          protocol:
                                                                            "POST",
                                                                          "request-body":
                                                                            {
                                                                              fids: [
                                                                                item.fid,
                                                                              ],
                                                                              mode: 2,
                                                                            },
                                                                          apiURL:
                                                                            "/files/delete_async",
                                                                          isAsyncDeletion: true,
                                                                          showDialog: true,
                                                                          warningText: `Are you sure you want to delete all distributed versions of - ${item.short_id.toUpperCase()} - from the other instances permanently? The file would still be available for you.`,
                                                                          fetchString:
                                                                            "snapshotStore/fetchCurrentFiles",
                                                                          objectName:
                                                                            item.short_id,
                                                                        },
                                                                        on: {
                                                                          error:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.errorMessage(
                                                                                $event.error
                                                                              )
                                                                            },
                                                                          deleting:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.emptySelected(
                                                                                !$event.value
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.isDevelopment &&
                                                        _vm.isSpaceAdmin &&
                                                        _vm.isMasterInstance &&
                                                        _vm.mergedOptions.delete
                                                          ? _c(
                                                              "v-list-item",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "subtitle-2 secondary--text",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "DeleteButton",
                                                                      {
                                                                        attrs: {
                                                                          objectType:
                                                                            "file",
                                                                          buttonName:
                                                                            "Delete for all",
                                                                          protocol:
                                                                            "POST",
                                                                          "request-body":
                                                                            {
                                                                              fids: [
                                                                                item.fid,
                                                                              ],
                                                                              mode: 1,
                                                                            },
                                                                          apiURL:
                                                                            "/files/delete_async",
                                                                          isAsyncDeletion: true,
                                                                          showDialog: true,
                                                                          warningText: `Are you sure you want to delete your and all distributed versions of - ${item.short_id.toUpperCase()} - permanently?`,
                                                                          fetchString:
                                                                            "snapshotStore/fetchCurrentFiles",
                                                                          objectName:
                                                                            item.short_id,
                                                                        },
                                                                        on: {
                                                                          error:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.errorMessage(
                                                                                $event.error
                                                                              )
                                                                            },
                                                                          deleting:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.emptySelected(
                                                                                !$event.value
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          )
                        }),
                        !items.length
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "5" } }, [
                                _c(
                                  "div",
                                  { staticClass: "mt-3 mb-3" },
                                  [
                                    _vm.filesFetching
                                      ? _c(
                                          "v-alert",
                                          {
                                            attrs: {
                                              dense: "",
                                              type: "info",
                                              text: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Loading the list of files, please wait..."
                                            ),
                                          ]
                                        )
                                      : _vm.pathArray.length > 1
                                      ? _c(
                                          "v-alert",
                                          {
                                            attrs: {
                                              dense: "",
                                              type: "warning",
                                              text: "",
                                            },
                                          },
                                          [_vm._v("No files.")]
                                        )
                                      : !_vm.filesFetching
                                      ? _c(
                                          "v-alert",
                                          {
                                            attrs: {
                                              dense: "",
                                              type: "warning",
                                              text: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Currently you don't have "
                                            ),
                                            _vm.folderTypeSelected ===
                                            _vm.folderTypesLabels
                                              .WORKSPACE_FILES
                                              ? _c("span", [
                                                  _vm._v("workspace"),
                                                ])
                                              : _vm.folderTypeSelected ===
                                                _vm.folderTypesLabels
                                                  .PERSONAL_FILES
                                              ? _c("span", [_vm._v("personal")])
                                              : _vm._e(),
                                            _vm._v(" files. Use the "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [_vm._v("Hidden Files")]
                                            ),
                                            _vm._v(
                                              " switch above to display any hidden files. "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-inline" }, [
      _c("span", { staticClass: "secondary--text font-weight-bold text-h6" }, [
        _vm._v("Files"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }